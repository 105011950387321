import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Main from "../layouts/main"
import Header from "../layouts/header"
import SEO from "../components/seo"
import Editorial from "../components/Fields/Editorial"
import Section from "../layouts/section"
import ContextConsumer from "../context/LanguageContext"
import { v4 as uuidv4 } from "uuid"
import { InternalLink } from "../components/Link"

const Post = props => {
  const craftData = useStaticQuery(graphql`
    query PostEntriesQuery {
      craftapi {
        entriesEN: entries (type: "news" siteId: "1") {
          uri
          ... on CraftAPI_news_news_Entry {
            id
            slug
            title
            editorial {
              ...on CraftAPI_editorial_sectionTitle_BlockType {
                titleText
                typeHandle
              }
              ... on CraftAPI_editorial_singleImage_BlockType {
                image {
                  src: url(transform: "desktop")
                }
                layout
                typeHandle
              }
              ...on CraftAPI_editorial_text_BlockType {
                copy
                typeHandle
              }
              ... on CraftAPI_editorial_textAndImage_BlockType {
                copy
                image {
                  srcMobile: url(transform: "mobile")
                  srcTablet: url(transform: "tablet")
                  srcDesktop: url(transform: "desktop")
                }
                position
                typeHandle
              }
              ... on CraftAPI_editorial_gallery_BlockType {
                sectionIntro
                lightboxMode
                images {
                  id
                  srcLandscape: url(transform: "landscape")
                  srcThumbnail: url(transform: "thumbnail")
                  title
                  focalPoint
                }
                typeHandle
              }
              ... on CraftAPI_editorial_item_BlockType {
                itemName
                itemInformation
                itemImage {
                  srcThumbnail: url(transform: "thumbnail")
                }
                typeHandle
              }
            }
          }
        }
        entriesCY: entries (type: "news" siteId: "2") {
          ... on CraftAPI_news_news_Entry {
            id
            slug
            title
            editorial {
              ...on CraftAPI_editorial_sectionTitle_BlockType {
                titleText
                typeHandle
              }
              ... on CraftAPI_editorial_singleImage_BlockType {
                image {
                  src: url(transform: "landscape")
                }
                layout
                typeHandle
              }
              ...on CraftAPI_editorial_text_BlockType {
                copy
                typeHandle
              }
              ... on CraftAPI_editorial_textAndImage_BlockType {
                copy
                image {
                  srcMobile: url(transform: "mobile")
                  srcTablet: url(transform: "tablet")
                  srcDesktop: url(transform: "desktop")
                }
                position
                typeHandle
              }
              ... on CraftAPI_editorial_gallery_BlockType {
                sectionIntro
                lightboxMode
                images {
                  id
                  srcLandscape: url(transform: "landscape")
                  srcThumbnail: url(transform: "thumbnail")
                  title
                  focalPoint
                }
                typeHandle
              }
              ... on CraftAPI_editorial_item_BlockType {
                itemName
                itemInformation
                itemImage {
                  srcThumbnail: url(transform: "thumbnail")
                }
                typeHandle
              }
            }
          }
        }
      }
    }
  `)

  const {
    pageContext: {
      id
    }
  } = props

  const {
    craftapi: {
      entriesEN,
      entriesCY,
    }
  } = craftData

  // match the page slug to the uri passed in by the createPage context (see gatsby-node.js)
  const en = entriesEN.filter(entry => entry.id === id)[0]
  const cy = entriesCY.filter(entry => entry.id === id)[0]

  return (
    <ContextConsumer>
      {({ data }) => (
        <React.Fragment key={uuidv4()}>
          {(data.currentLanguage === 'en') && (
            <>
              <SEO title={en.title} />
              <Main margin="2rem 0">
                <Section padding="2rem 2rem 0.5rem 2rem">
                  <Header level={1}>{en.title}</Header>
                </Section>
                <Editorial data={en.editorial}/>
                <Section  padding={`2rem`}>
                  <InternalLink to={`/latest-news`}>&larr; Back to latest news</InternalLink>
                </Section>
              </Main>
            </>
          )}
          {(data.currentLanguage === 'cy') && (
            <>
              <SEO title={cy.title} />
              <Main margin="2rem 0">
                <Section padding="2rem 2rem 0.5rem 2rem">
                  <Header level={1}>{cy.title}</Header>
                </Section>
                <Editorial data={cy.editorial}/>
                <Section padding="2rem">
                  <InternalLink to={`/latest-news`}>&larr; Back to latest news</InternalLink>
                </Section>
              </Main>
            </>
          )}
        </React.Fragment>
      )}
    </ContextConsumer>
  )
}

export default Post
